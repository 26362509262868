import { Button } from '@goatlab/react-ui'
import { Popover, PopoverContent, PopoverTrigger } from '@goatlab/react-ui'
import { ArrowRight, CameraIcon, Pencil } from 'lucide-react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { backendHook } from '@api/trpc'
import { useAuth } from '@components/auth/store/auth.store'
import { ProfileSpaceLayout } from '@components/spaces/profileSpaceLayout'
import { RichTextDisplay } from '@components/ui/forms/richText/RichTextDisplay'
import {
  defaultBackgroundImg,
  defaultProfileImg,
} from '@components/ui/images/images'
import { LandingPageLoadingShimmer } from '@components/ui/loaders/LandingPageLoadingShimmer'
import { useSwiper } from '@components/ui/swipper/useSwiper'
import { useSite } from '@contexts/site/site.context'
import { useIs } from '@src/hooks/useIs'
import { typesenseService } from '@src/services/typesense/typesense.service'

export const Space: FC = () => {
  const siteContext = useSite()
  const { isSpacePage } = useIs()
  const router = useRouter()
  const slug = String(router.query?.space)

  const { data, isLoading } = typesenseService.useGetMainPageBySlug(slug)

  const { user } = useAuth()
  const [, setIsAccountOwner] = useState(false)
  const accountId = data?.id

  const getSocialConnection =
    backendHook.account.getSocialConnectionByAccountId.useQuery(
      {
        accountId: accountId as string,
      },
      {
        enabled: !!accountId,
      },
    )

  useEffect(() => {
    setIsAccountOwner(user?.account?.id === accountId)
  }, [accountId, user])

  const account = getSocialConnection.data?.account
  const accountSocial = getSocialConnection.data?.social

  useSwiper('.swiper', {}, [siteContext.accountId, siteContext.landingPageHTML])

  if (!siteContext.accountId && !isSpacePage) {
    return <LandingPageLoadingShimmer />
  }

  if (isSpacePage && !data && isLoading) {
    return <LandingPageLoadingShimmer />
  }

  return (
    <ProfileSpaceLayout>
      {/* <style
        dangerouslySetInnerHTML={{
          __html: siteContext.isSpace
            ? siteContext.landingPageCSS
            : data?.landingPageCSS || '',
        }}></style>
      <RichTextDisplay
        content={
          siteContext.isSpace
            ? siteContext.landingPageHTML
            : data?.landingPageHTML || ''
        }
      /> */}

      <div className="pb-10 grid grid-cols-12 gap-4 md:p-6 mt-5 md:max-[1060px]:w-10/12 md:max-[768px]:mx-auto pt-2">
        {/** Right Panel */}
        <div className="col-[10_/_span_2] lg:col-[9_/_span_1] row-[1_/_span_2] rounded-md w-[300px] h-fit max-[768px]:col-span-12 max-[768px]:row-[6_/_span_1] max-[768px]:w-full">
          {/** One of the possible contents */}
          <div className="divide-y bg-white dark:bg-[rgb(29,34,38)] rounded-md">
            <div className="flex justify-between">
              <div className="p-4 dark:text-white pr-2 flex-col">
                <span className="flex dark:text-white font-bold pr-2">
                  Profile language
                </span>
                <span className="flex text-gray-400 text-[14px]">English</span>
              </div>
              <Pencil className="mt-4 mr-4 w-5 h-5 text-gray-400" />
            </div>

            <div className="flex justify-between">
              <div className="p-4 dark:text-white pr-2 flex-col">
                <span className="flex pr-2 font-bold">
                  Public profile & URL
                </span>
                <span className="flex text-gray-400 text-[14px]">
                  www.gealium.com/{data?.slug}
                </span>
              </div>
              <Pencil className="mt-4 mr-4 w-5 h-5 text-gray-400" />
            </div>
          </div>
        </div>

        {/** Body*/}

        <div className="col-[1_/_span_12] md:col-[1_/_span_9] lg:col-[2_/_span_7] row-[1_/_span_2] bg-white dark:bg-[rgb(29,34,38)] rounded-md max-[768px]:row-[1_/_span_2] max-[768px]:col-span-12">
          <div>
            <div>
              <div className="flex">
                <Image
                  width={1400}
                  height={1000}
                  src={account?.backgroundImage || defaultBackgroundImg}
                  alt=""
                  className="h-[200px] w-full rounded-tl-md rounded-tr-md static z-10"
                />
                <div className="-ml-12 mt-5 rounded-full h-8 w-8 bg-[#fff] flex items-center justify-center z-20">
                  <CameraIcon className="text-gray-600 w-5 h-5" />
                </div>
              </div>
              <div className="w-36 h-36">
                <Image
                  width={200}
                  height={200}
                  src={account?.profilePicture || defaultProfileImg}
                  alt=""
                  className="w-[144px] h-[144px] -mt-24 ml-3 rounded-full absolute z-20 border-2 border-gray-200 dark:border-[rgb(29,34,38)] max-[1060px]:w-24 max-[1060px]:h-24 max-[1060px]:-mt-16"
                />
              </div>
            </div>
            <div className="flex justify-between -mt-24 mb-8">
              <div className="ml-5">
                <h1 className="font-bold dark:text-white text-2xl max-[990px]:text-[18px]">
                  {data?.displayName}
                </h1>
                <p className="font-medium pb-1">{account?.title}</p>

                <p className="text-gray-400 text-sm py-1">
                  {account?.city ? `${account?.city},` : ''} {account?.country}.
                  <span className="text-blue-600 font-medium pl-2">
                    Contact info
                  </span>
                </p>
                <p className="text-blue-600 text-sm py-1">
                  {accountSocial?.totalFriends &&
                  accountSocial.totalFriends > 0 ? (
                    `${accountSocial.totalFriends} connections`
                  ) : (
                    <></>
                  )}
                </p>
                <div className="mt-2 space-x-4">
                  <Button className="rounded-l-full rounded-r-full h-9">
                    Follow
                  </Button>

                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={'outline'}
                        className="rounded-l-full rounded-r-full h-9">
                        More
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80" align="start">
                      <div className="grid gap-4">
                        <div className="space-y-2">
                          <h4 className="font-medium leading-none">
                            Dimensions
                          </h4>
                          <p className="text-sm text-muted-foreground">
                            Set the dimensions for the layer.
                          </p>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div className="max-[375px]:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  data-supported-dps="24x24"
                  fill="currentColor"
                  className="mercado-match mb-2 ml-32 max-[1060px]:ml-24 text-gray-400"
                  width="24"
                  height="24"
                  focusable="false">
                  <path d="M21.13 2.86a3 3 0 00-4.17 0l-13 13L2 22l6.19-2L21.13 7a3 3 0 000-4.16zM6.77 18.57l-1.35-1.34L16.64 6 18 7.35z"></path>
                </svg>
                <p className="text-white font-bold mr-3 max-[990px]:text-[13px]">
                  Marwari college ranchi
                </p>
              </div>
            </div>
          </div>
        </div>

        {/** Analitycs */}

        <div className="col-[1_/_span_12] md:col-[1_/_span_9] lg:col-[2_/_span_7] border-solid bg-white dark:bg-[rgb(29,34,38)] rounded-md row-[3_/_span_1] flex items-center justify-between max-[768px]:col-span-12">
          <p className="ml-5">
            <div className="flex-col pt-3">
              <div>
                <span className="dark:text-white font-bold">
                  Analytics & tools
                </span>
              </div>
              <div>
                <span className="text-gray-400 font-bold text-[12px] pr-1">
                  Post impressions past 7 days
                </span>
                <span className="dark:text-white font-bold text-[12px]">
                  197
                </span>
                <span className="text-green-400 font-bold text-[12px] ml-1">
                  <i className="fa-solid fa-caret-up"></i>19,600%
                </span>
              </div>
            </div>
          </p>
          <ArrowRight className="mr-5 text-gray-400" />
        </div>

        {/** About */}
        <div className="col-[1_/_span_12] md:col-[1_/_span_9] lg:col-[2_/_span_7] border-solid bg-white dark:bg-[rgb(29,34,38)] rounded-md row-[4_/_span_1] flex items-center justify-between max-[768px]:col-span-12">
          <p className="ml-5">
            <div className="flex-col pt-3">
              <div>
                <span className="dark:text-white font-bold">About</span>
              </div>
              <div className="pt-3">
                <RichTextDisplay content={user?.account?.about} />
              </div>
            </div>
          </p>
        </div>
      </div>
    </ProfileSpaceLayout>
  )
}

export default Space
