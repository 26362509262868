import { Skeleton } from '@goatlab/react-ui'
import React, { FC } from 'react'

interface LandingPageLoadingShimmerProps {}

export const LandingPageLoadingShimmer: FC<
  LandingPageLoadingShimmerProps
> = () => {
  return (
    <div className="w-full h-full flex flex-col">
      {/* Header */}
      <div className="flex items-center p-4 border-b">
        <Skeleton className="h-10 w-40 rounded-md" /> {/* Logo */}
        <div className="flex-grow"></div>
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} className="h-6 w-24 rounded-md mx-2" />
        ))}
      </div>

      {/* Carousel */}
      <div className="relative h-64 p-4">
        <Skeleton className="absolute top-0 left-0 w-full h-full rounded-md" />{' '}
        {/* Carousel image */}
        <div className="absolute bottom-0 left-0 p-4">
          <Skeleton className="h-12 w-48 rounded-md" /> {/* Call to action */}
        </div>
      </div>

      {/* Blog Posts */}
      <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Array.from({ length: 6 }).map((_, idx) => (
          <div key={idx} className="flex flex-col p-4 border rounded-md">
            <Skeleton className="h-40 w-full mb-4 rounded-md" />{' '}
            {/* Blog image */}
            <Skeleton className="h-6 w-3/4 mb-2 rounded-md" />{' '}
            {/* Blog title */}
            <Skeleton className="h-4 w-1/2 mb-4 rounded-md" />{' '}
            {/* Blog excerpt */}
            <Skeleton className="h-6 w-24 rounded-md" />{' '}
            {/* Read more button */}
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="p-4 border-t">
        {Array.from({ length: 3 }).map((_, idx) => (
          <div key={idx} className="flex space-x-4">
            {Array.from({ length: 4 }).map((_, idx2) => (
              <Skeleton key={idx2} className="h-8 w-32 rounded-md" />
            ))}
          </div>
        ))}
      </div>

      {/* Footer Links */}
      <div className="p-4 border-t flex space-x-4">
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} className="h-6 w-24 rounded-md" />
        ))}
      </div>
    </div>
  )
}
