import { useEffect } from 'react'
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperOptions } from 'swiper/types'

export const useSwiper = (
  selector: string,
  options: SwiperOptions,
  reload: any[],
) => {
  useEffect(() => {
    Swiper.use([Navigation, Pagination, Autoplay])

    new Swiper(selector, {
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      ...options,
    })
  }, [selector, ...reload])
}
